<script setup lang="ts">
const props = defineProps({ image: { type: String, default: null } })
</script>

<template>
  <div class="relative sm:container">
    <twic-img
      :src="useTwicPicsBlogUrl(props.image)"
      class="will-change-transform sm:rounded-b"
      ratio="16/8"
      focus="auto"
      eager
    />
    <div class="w-full space-y-3 bg-white p-4 lg:absolute lg:bottom-0 lg:max-w-xl lg:rounded-tr lg:p-8">
      <div
        v-if="$slots.suptitle"
        class="text-xs font-semibold uppercase text-pink-500"
      >
        <slot name="suptitle" />
      </div>
      <h1
        v-if="$slots.title"
        class="text-xl font-semibold md:text-2xl lg:text-4xl"
      >
        <slot name="title" />
      </h1>
      <div
        v-if="$slots.subtitle"
        class="text-lg line-clamp-4"
      >
        <slot name="subtitle" />
      </div>
    </div>
  </div>
</template>
