<script setup lang="ts">
import { isHomePageContent } from '~/types/api/page'
import type { PreloadDefinition } from '@/types/app'

const route = useRoute()

const { data: pageContent, pending, error } = await useLazyAsyncData('home', () => useWagtailApiPageGetByPath(route.path))

if (error.value) {
  throw createError({ statusCode: 404, statusMessage: error.value.message })
}

const preloads = ref<PreloadDefinition[]>([])
if (isHomePageContent(pageContent.value)) {
  const heroImage = useTwicPicsBlogUrl(pageContent.value?.featured_article?.featured_image_rendered?.url_relative)
  if (heroImage) {
    const { public: { twicpics: { domain } } } = useRuntimeConfig()
    preloads.value.push(
      {
        href: `${domain}${heroImage}?twic=v1/focus=auto/cover=610x305`,
        media: '(min-width: 640px)',
      },
      {
        href: `${domain}${heroImage}?twic=v1/focus=auto/cover=740x370`,
        media: '(min-width: 768px)',
      },
      {
        href: `${domain}${heroImage}?twic=v1/focus=auto/cover=990x495`,
        media: '(min-width: 1024px)',
      },
      {
        href: `${domain}${heroImage}?twic=v1/focus=auto/cover=1250x625`,
        media: '(min-width: 1280px)',
      },
      {
        href: `${domain}${heroImage}?twic=v1/focus=auto/cover=1410x705`,
        media: '(min-width: 1440px)',
      },
    )
  }
}
useHead(usePageHead({ pageContent, addAlternate: true, preloads: preloads.value }))
</script>

<template>
  <div>
    <LazyAppPageLoading v-if="pending" />
    <template v-else-if="isHomePageContent(pageContent)">
      <AppHomeHeaderPage
        v-if="pageContent.featured_article?.featured_image_rendered"
        :image="pageContent.featured_article.featured_image_rendered.url_relative"
      >
        <template
          v-if="pageContent.featured_article.tags.length"
          #suptitle
        >
          {{ pageContent.featured_article.tags[0].name }}
        </template>

        <template #title>
          <NuxtLink
            :to="pageContent.featured_article.html_path"
            class="block focus-visible:z-10 focus-visible:rounded focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          >
            {{ pageContent.featured_article.title }}
            <span
              class="absolute inset-0"
              aria-hidden="true"
            />
          </NuxtLink>
        </template>

        <template #subtitle>
          <p v-if="pageContent.featured_article.sub_title">
            {{ pageContent.featured_article.sub_title }}
          </p>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-else
            v-html="pageContent.featured_article.description"
          />
          <!-- eslint-enable vue/no-v-html -->
        </template>
      </AppHomeHeaderPage>

      <template
        v-for="(block, index) in pageContent.body"
        :key="block.id"
      >
        <AppPostBlock
          :id="block.id"
          :type="block.type"
          :content="block.value"
          :reverse="index % 2 !== 0"
        />
      </template>
    </template>
  </div>
</template>
